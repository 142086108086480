@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600');
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
.btn{
    display: inline-block !important;
}
.btn button {
  color: #fff;
  font: inherit;
  font-weight: bold;
  background-color: #0D47A1;
  font-family: 'Titillium Web', sans-serif !important;
  width: 74px;
  height: 74px;
  text-transform: capitalize;
  border: none;
  padding: 0.6rem;
  outline: none;
  margin: 1rem;
  
  box-sizing: border-box;
  border-radius: 50%;
  transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.btn button:hover {
  background-color: #a5d6a7;
  transform: scale(1.1);
}
.btn button:active {
  background-color: #a5d6a7;
  transform: scale(1);
}

.slide {
  width: 290px;
  height: 350px;
  background: url("https://radioafricagroup.github.io/the-star-scripts/pollbg.jpg");
  /* margin: auto; */
  position: relative;
  background-position: center;
  background-size: cover;
  transition-duration: 1s;
}
.slide .overlay {
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.8) 100%);
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
}
    
.pie-chart {
  margin: auto;
  margin-left: 1.8rem;
  margin-right: 1.8rem;
  margin-top: 1.3rem;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
}
.question{
  font-size: 1.6em;
  color: #fff;
  padding: 0 7px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif !important;
  margin-block-start: 2em;
  margin-block-end: 1em;
  transition-duration: 1s;
}

.radial-inner-bg {
  border-radius: 50%;
  width: 74px;
  height: 74px;
  top: 3px;
  left: 3px;
  background: #0D47A1; 
  color: #fff;
  text-align: center;
  position: relative;
}
span.meta {
  display: block;
  padding-top: 10px;
  font-family: 'Titillium Web', sans-serif !important;
  font-weight: 600;
  color: #fff;
  font-size: 1.5em;
  margin-bottom: -10px;
}

span.metatotal {
  display: block;
  padding-top: 10px;
  font-family: 'Titillium Web', sans-serif !important;
  font-weight: 600;
  color: #fff;
  font-size: 1em;
  margin-bottom: -10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
small{
    font-family: 'Titillium Web', sans-serif !important;
    font-size: 1.1em;
    text-transform: capitalize;
}

@media only screen and (max-width: 320px) {
  .question{
    font-size: 1.3em;
  }
  .slide {
    width: 280px;
  }

}

.progress-0 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #0D47A1), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #41ADE5), color-stop(50%, #0D47A1), to(#fff));
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(90deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-1 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(93.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-2 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(97.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-3 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(100.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-4 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(104.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-5 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(108deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-6 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(111.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-7 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(115.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-8 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(118.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-9 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(122.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-10 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(126deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-11 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(129.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-12 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(133.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-13 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(136.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-14 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(140.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-15 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(144deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-16 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(147.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-17 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(151.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-18 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(154.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-19 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(158.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-20 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(162deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-21 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(165.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-22 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(169.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-23 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(172.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-24 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(176.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-25 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #0D47A1), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #41ADE5), color-stop(50%, #0D47A1), to(#fff));
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(180deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-26 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(183.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-27 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(187.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-28 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(190.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-29 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(194.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-30 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(198deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-31 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(201.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-32 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(205.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-33 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(208.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-34 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(212.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-35 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(216deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-36 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(219.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-37 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(223.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-38 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(226.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-39 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(230.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-40 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(234deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-41 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(237.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-42 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(241.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-43 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(244.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-44 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(248.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-45 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(252deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-46 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(255.6deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-47 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(259.2deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-48 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(262.8deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-49 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(90deg, #0D47A1 50%, transparent 50%, transparent), linear-gradient(266.4deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-50 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #41ADE5), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #41ADE5), color-stop(50%, #0D47A1), to(#fff));
  background-image: linear-gradient(-90deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-51 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-86.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-52 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-82.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-53 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-79.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-54 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-75.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-55 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-72deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-56 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-68.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-57 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-64.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-58 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-61.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-59 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-57.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-60 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-54deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-61 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-50.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-62 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-46.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-63 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-43.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-64 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-39.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-65 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-36deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-66 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-32.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-67 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-28.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-68 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-25.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-69 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-21.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-70 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-18deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-71 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-14.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-72 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-10.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-73 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-7.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-74 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(-3.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-75 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #41ADE5), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #41ADE5), color-stop(50%, #0D47A1), to(#fff));
  background-image: linear-gradient(0deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-76 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(3.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-77 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(7.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-78 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(10.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-79 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(14.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-80 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(18deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-81 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(21.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-82 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(25.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-83 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(28.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-84 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(32.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-85 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(36deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-86 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(39.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-87 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(43.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-88 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(46.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-89 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(50.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-90 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(54deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-91 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(57.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-92 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(61.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-93 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(64.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-94 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(68.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-95 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(72deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-96 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(75.6deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-97 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(79.2deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-98 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(82.8deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-99 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: linear-gradient(86.4deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.progress-100 {
  -webkit-transition: .5s;
  transition: .5s;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #41ADE5), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #41ADE5), color-stop(50%, #0D47A1), to(#fff));
  background-image: linear-gradient(90deg, #41ADE5 50%, transparent 50%, transparent), linear-gradient(270deg, #41ADE5 50%, #0D47A1 50%, #0D47A1);
}

.social{
  position: absolute;
  bottom: 2px;
  left: 15px;
}
.social a{
  padding-right: 20px;
}

.branding{
  position: absolute;
    height: 30px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    right: 15px;
    top: 10px;
}
