body {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
}

h2{
  /*font-style: italic;*/
  padding-left: 20px;
  padding-right: 20px;
}

.buttonWidth {
  width: 250px;
}


